import React, { useMemo } from "react";
import { BrandStatsBySize } from "@core/types/domain/livestream/BrandStats";
import {
  AttributeChart,
  AttributeChartProps,
} from "~/screens/external/live/brand/AttributeChart";
import { useMainValueUtils } from "~/screens/app/detection/live/stats/useMainValueUtils";
import { LiveStatsValue } from "~/screens/app/detection/live/stats/options/useLiveStatsOptions";
import { SocketVideoInfo } from "~/screens/app/detection/live/data/useSocketIoStats";
import { roundN } from "~/utils/js/mathUtils";

interface BrandAttributeChartProps {
  label: string;
  type: LiveStatsValue;
  stats: BrandStatsBySize;
  info: SocketVideoInfo;
  // summedStats: BrandStats
}

export function BrandAttributeChart({
  type,
  label,
  stats,
  info,
}: BrandAttributeChartProps) {
  const { getMainValue, formatMainValue } = useMainValueUtils({
    mainValue: type,
  });
  const chartProps: Omit<AttributeChartProps, "label"> = useMemo(() => {
    const mainValue = getMainValue(stats?.["all_all"]);
    return {
      value: formatMainValue(mainValue),
      percent: roundN(getPercent(type, mainValue, info), 2),
    };
  }, [stats, info]);

  return <AttributeChart {...chartProps} label={label} />;
}

function getPercent(
  type: LiveStatsValue,
  value: number,
  info: SocketVideoInfo
) {
  switch (type) {
    case LiveStatsValue.lduration:
      return mapToScale(info.fduration * 0.75, value);
    case LiveStatsValue.exclusive:
      return mapToScale(0.7, value);
    case LiveStatsValue.scaleSum:
      return mapToScale(0.5, value);
    default:
      return mapToScale(1, value);
  }
}

function mapToScale(maxValue: number, value: number) {
  return Math.min(1, value / maxValue);
}
