import React from "react";
import styled from "@emotion/styled";
import { TaskInfo } from "@core/types/detection/DetectionTypes";
import { LivestreamTypeExternal } from "@core/types/domain/Livestream";
import { useSocketIoStats } from "../../app/detection/live/data/useSocketIoStats";
import { useQueryParam } from "~/utils/hooks/useQueryParam";
import { toBool, toInt } from "~/utils/jsUtils";
import { useExternalLivestream } from "~/screens/external/stream/useExternalStream";
import { useLivestreamTask } from "~/screens/app/detection/livestreams/data/useLivestreamsStatus";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { ErrorNotification } from "~/components/notification/ErrorNotification";
import Notification from "~/components/notification/Notification";
import { isCollapsed } from "~/utils/style/styleUtils";
import { WebRtcVideo } from "~/modules/webrtc/WebRtcVideo";
import { DashboardCard } from "~/screens/external/live/card/DashboardCard";
import { GaugeChartsSection } from "~/screens/external/live/GaugeChartsSection";
import { TopSponsorView } from "~/screens/external/live/top/TopSponsorView";
import { PieChartSection } from "~/screens/external/live/pie/PieChartSection";
import { LiveBarsSection } from "~/screens/external/live/bars/LiveBarsSection";
import { BoldText } from "~/screens/external/live/utils/BoldText";

export function NewLiveStreamView() {
  const id = useQueryParam("id", toInt);
  const code = useQueryParam("code");
  const { data: stream, isLoading: l1 } = useExternalLivestream(id, code);
  const { data: task, isLoading: l2 } = useLivestreamTask(id, stream?.server);
  if (l1 || l2) return <AppPageSpinner />;
  if (!stream)
    return (
      <ErrorNotification>
        Failed to get the stream. This could be for technical reasons or because
        you do not have the necessary authorizations.
      </ErrorNotification>
    );
  if (!task)
    return (
      <Notification type={"info"}>
        No video detection is currently active for this stream. As soon as a
        live broadcast begins, this page updates automatically.
      </Notification>
    );
  return <View task={task} stream={stream} />;
}

function View({
  task,
  stream: { server, start, title },
}: {
  task: TaskInfo;
  stream: LivestreamTypeExternal;
}) {
  const socketUrl = task.socketUrl || server.socketUrl || server.url;
  const socketStats = useSocketIoStats(task.id, socketUrl);
  const isVideoView = useQueryParam("video", toBool);

  return (
    <div>
      <Grid className={isVideoView ? WITH_VIDEO : undefined}>
        <DashboardCard title={"Date"} className={"info-1"}>
          <BoldText>{start?.toLocaleDateString()}</BoldText>
        </DashboardCard>
        <DashboardCard title={"Analyzed game"} className={"info-2"}>
          <BoldText>{title}</BoldText>
        </DashboardCard>
        <DashboardCard title={"Top sponsor"} className={"info-3"}>
          <TopSponsorView stats={socketStats.accumulated} />
        </DashboardCard>
        <DashboardCard title={"Exposure in hh:mm:ss"} className={"main"}>
          <LiveBarsSection
            stats={socketStats.accumulated}
            brandLimit={brandLimit}
          />
        </DashboardCard>
        <DashboardCard title={"Share of voice"} className={"side-1"}>
          <PieChartSection stats={socketStats.accumulated} limit={brandLimit} />
        </DashboardCard>
        <DashboardCard title={"KPI by top 3 sponsor"} className={"side-2"}>
          <GaugeChartsSection sockeStats={socketStats} />
        </DashboardCard>
        {isVideoView ? (
          <DashboardCard title={"Video"} className={"side-3"}>
            <VideoPlayer url={task.webrtcUrl} />
          </DashboardCard>
        ) : null}
      </Grid>
      {/*<FirstRow>*/}
      {/*  <VideoPlayer url={task.webrtcUrl} />*/}
      {/*  <VideoPlayer*/}
      {/*    src={*/}
      {/*      "https://www.nlm.nih.gov/news/videos/30th_Overview_05-07_Revised-800.mp4"*/}
      {/*    }*/}
      {/*    autoplay*/}
      {/*    muted*/}
      {/*    fluid*/}
      {/*  />*/}
      {/*  {socketStats.accumulated && (*/}
      {/*    <div>*/}
      {/*      <LiveStatsOptionsSelector />*/}
      {/*      <LiveStatsBars*/}
      {/*        overrideOptions={viewOptions}*/}
      {/*        stats={socketStats.accumulated}*/}
      {/*        onBrandClick={(brand) => {*/}
      {/*          setActiveBrands((prev) => {*/}
      {/*            if (prev.indexOf(brand.name) !== -1) {*/}
      {/*              return prev.filter((b) => b !== brand.name)*/}
      {/*            } else return [...prev, brand.name]*/}
      {/*          })*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</FirstRow>*/}
    </div>
  );
}

const brandLimit = 18;
const WITH_VIDEO = "with-video";

const Grid = styled.div`
  display: grid;
  max-width: 100em;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: 1fr 1fr 1.5fr;
  grid-template-rows: 0.2fr 1fr 1fr;
  gap: 1em;
  grid-template-areas:
    "info-1 info-2 info-3"
    "main main side-1"
    "main main side-2";
  &.with-video {
    grid-template-areas:
      "info-1 info-2 info-3"
      "main main side-1"
      "main main side-2"
      "main main side-3";
  }

  ${isCollapsed} {
    display: flex;
    flex-wrap: wrap;
    & > * {
      flex: 100%;
    }
  }

  .info-1 {
    grid-area: info-1;
  }
  .info-2 {
    grid-area: info-2;
  }
  .info-3 {
    grid-area: info-3;
  }
  .main {
    grid-area: main;
  }
  .side-1 {
    grid-area: side-1;
  }
  .side-2 {
    grid-area: side-2;
  }
  .side-3 {
    grid-area: side-3;
  }
`;

const VideoPlayer = styled(WebRtcVideo)`
  width: 100%;
  max-width: 800px;
`;

const FirstRow = styled.div`
  display: flex;
  gap: 3em;
  ${isCollapsed} {
    display: block;
    & > div {
      margin-bottom: 1em;
    }
  }
`;
