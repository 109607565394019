import React from "react";
import { StatsByBrand } from "@core/types/domain/livestream/BrandStats";
import { useTopBrandsResolved } from "~/screens/external/live/utils/useTopBrands";
import { BoldText } from "~/screens/external/live/utils/BoldText";

interface TopSponsorViewProps {
  stats: StatsByBrand;
}

export function TopSponsorView({ stats }: TopSponsorViewProps) {
  const activeBrand = useTopBrandsResolved(stats, 1)?.[0];
  return <BoldText>{activeBrand?.label}</BoldText>;
}
