import React from "react";
import GaugeChart from "react-gauge-chart";
import styled from "@emotion/styled";
import { useId } from "~/utils/reactUtils";

export interface AttributeChartProps {
  percent: number;
  value: string;
  label: string;
}

export const AttributeChart = React.memo(AttributeChart_);
function AttributeChart_({ label, percent, value }: AttributeChartProps) {
  const id = useId();
  return (
    <Container>
      <GaugeChart
        id={id}
        colors={["#FF3131", "#32CD32"]}
        textColor={"black"}
        needleColor={"rgba(0,0,0,.3)"}
        needleBaseColor={"rgba(0,0,0,1)"}
        nrOfLevels={5}
        arcWidth={0.25}
        arcPadding={0.1}
        cornerRadius={1}
        percent={percent}
        formatTextValue={() => value}
      />
      <Label>{label}</Label>
    </Container>
  );
}

const Label = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: -0.5em;
  text-align: center;
`;

const Container = styled.div`
  width: 100px;
  position: relative;

  text {
    background-color: white;
  }
`;
