import Chart from "chart.js";

export function initChartJs() {
  // can be used to apply global configuration
}

export function disableChartAnimations() {
  try {
    Chart.defaults.global.animation = undefined;
  } catch (e) {
    console.warn(e);
  }
}
