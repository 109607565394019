import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { extLiveColor } from "~/screens/external/live/utils/liveConst";

interface DashboardCardProps {
  title: string;
  children: ReactNode;
  className: string;
}

export function DashboardCard(props: DashboardCardProps) {
  return (
    <Container className={props.className}>
      <Title>{props.title}</Title>
      <Content>{props.children}</Content>
    </Container>
  );
}

const Title = styled.div`
  background-color: ${extLiveColor.accent};
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5em;
  text-align: center;
  border-radius: 0.4em;
`;

const Content = styled.div`
  padding: 1em 2em;
`;

const Container = styled.div`
  background-color: #f2ede7;
`;
