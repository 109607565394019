import React from "react";
import { StatsByBrand } from "@core/types/domain/livestream/BrandStats";
import styled from "@emotion/styled";
import { ChartData, ChartTooltipItem } from "chart.js";
import BetterPieChart from "~/components/charts/BetterPieChart";
import { usePieChartDataFromStats } from "~/screens/external/live/pie/usePieChartDataFromStats";
import { PieChartOptions } from "~/components/charts/PieChart";
import { sum } from "~/utils/jsUtils";
import { formatPercent } from "~/utils/formatUtils";

interface PieChartSectionProps {
  stats: StatsByBrand;
  limit: number;
}

export function PieChartSection({ stats, limit }: PieChartSectionProps) {
  const data = usePieChartDataFromStats(stats, limit);
  if (!data) return null;
  return (
    <Wrapper>
      <BetterPieChart data={data} options={chartOptions} />
    </Wrapper>
  );
}

const chartOptions: PieChartOptions = {
  options: {
    tooltips: {
      callbacks: {
        label(
          { datasetIndex, index }: ChartTooltipItem,
          data: ChartData
        ): string | string[] {
          const max = sum(data.datasets[datasetIndex].data as number[]);
          const item = data.datasets[datasetIndex].data[index] as number;
          const label = data.labels[index];
          return `${label}: ${formatPercent((item / max) * 100, 2)}`;
        },
      },
    },
  },
};

const Wrapper = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
`;
