import React from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";
import { ExternalPage } from "~/layouts/external/ExternalPage";
import { NewLiveStreamView } from "~/screens/external/live/NewLiveStreamView";
import { ReactQueryProvider } from "~/utils/fetch/ReactQueryProvider";

export function NewLiveStreamPage() {
  return (
    <ExternalPage>
      <Global styles={global} />
      <ReactQueryProvider>
        <PageWrapper>
          <NewLiveStreamView />
        </PageWrapper>
      </ReactQueryProvider>
    </ExternalPage>
  );
}

const global = css`
  html {
    background-color: white;
  }
`;

const PageWrapper = styled.div`
  padding: 1em;
`;
