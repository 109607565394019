import React from "react";
import styled from "@emotion/styled";
import {
  BrandGaugeCharts,
  BrandGaugeChartsProps,
} from "~/screens/external/live/brand/BrandGaugeCharts";
import { useTopBrands } from "~/screens/external/live/utils/useTopBrands";

interface GaugeChartsSectionProps {
  sockeStats: BrandGaugeChartsProps["socketStats"];
}

export function GaugeChartsSection({ sockeStats }: GaugeChartsSectionProps) {
  const topBrands = useTopBrands(sockeStats.accumulated, 3);
  if (!sockeStats.accumulated) return null;
  return (
    <Wrapper>
      <BrandGaugeCharts activeBrands={topBrands} socketStats={sockeStats} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
`;
