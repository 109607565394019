import React, { useMemo } from "react";
import { StatsByBrand } from "@core/types/domain/livestream/BrandStats";
import styled from "@emotion/styled";
import { LiveStatsBarsMemoized } from "~/screens/app/detection/live/stats/bars/LiveStatsBars";
import {
  LiveStatsOptions,
  LiveStatsValue,
} from "~/screens/app/detection/live/stats/options/useLiveStatsOptions";

interface LiveBarsProps {
  stats: StatsByBrand;
  brandLimit: number;
}

export function LiveBarsSection({ stats: stats, brandLimit }: LiveBarsProps) {
  const viewOptions = useMemo<Partial<LiveStatsOptions>>(() => {
    return {
      maxCount: brandLimit,
      mainValue: LiveStatsValue.lduration,
      splitBySize: true,
      freshnessArrows: true,
    };
  }, [brandLimit]);
  if (!stats) return null;
  return (
    <Container>
      <LiveStatsBarsMemoized
        overrideOptions={viewOptions}
        stats={stats}
        // onBrandClick={(brand) => {
        //   setActiveBrands((prev) => {
        //     if (prev.indexOf(brand.name) !== -1) {
        //       return prev.filter((b) => b !== brand.name)
        //     } else return [...prev, brand.name]
        //   })
        // }}
      />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
`;
