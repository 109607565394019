import {
  BrandStats,
  StatsByBrand,
} from "@core/types/domain/livestream/BrandStats";
import { useEffect, useMemo, useState } from "react";
import { EMPTY_ARRAY, notNull, sort } from "~/utils/jsUtils";
import { useBrands } from "~/services/brands/fetchBrands";

export function useTopBrands(
  stats: StatsByBrand,
  count: number,
  getValue = defaultGetValue
) {
  const [topBrands, setTopBrands] = useState<string[]>(EMPTY_ARRAY);
  useEffect(() => {
    if (!stats) return;
    const topBrands = getTopStats(stats, getValue)
      .map((s) => s["All_all"]?.name)
      .filter(notNull);
    const truncated = topBrands.splice(0, count);
    setTopBrands((prev) => {
      if (prev?.toString() === truncated.toString()) return prev;
      // if no changes
      else return truncated;
    });
  }, [stats, count, getValue]);
  return topBrands;
}

export function getTopStats(
  stats: StatsByBrand,
  getValue: GetValue = (s) => s.duration
) {
  return sort(Object.values(stats), (a, b) => {
    return getValue(a["All_all"]) < getValue(b["All_all"]) ? 1 : -1;
  });
}

type GetValue = (stats: BrandStats) => number;
const defaultGetValue: GetValue = (s) => s?.duration;

export function useTopBrandsResolved(stats: StatsByBrand, count: number) {
  const names = useTopBrands(stats, count);
  const { brandByName } = useBrands();
  return useMemo(() => {
    if (!brandByName) return undefined;
    return names.map((n) => brandByName[n]);
  }, [names, brandByName]);
}
