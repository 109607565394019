import React from "react";
import styled from "@emotion/styled";
import { BrandStatsBySize } from "@core/types/domain/livestream/BrandStats";
import { Brand } from "@core/types/domain/Brand";
import { useDebounce } from "use-debounce";
import { LiveStatsValue } from "../../../app/detection/live/stats/options/useLiveStatsOptions";
import { BrandAttributeChart } from "~/screens/external/live/brand/BrandAttributeChart";
import {
  SocketVideoInfo,
  UseSocketIoStats,
} from "~/screens/app/detection/live/data/useSocketIoStats";
import { useBrands } from "~/services/brands/fetchBrands";

export interface BrandGaugeChartsProps {
  activeBrands: string[];
  socketStats: UseSocketIoStats;
}

export function BrandGaugeCharts({
  activeBrands,
  socketStats,
}: BrandGaugeChartsProps) {
  // const stats = statsByBrand
  const { brandByName } = useBrands();
  const [stats] = useDebounce(socketStats, 1500, { leading: true });
  if (!stats.accumulated) return null;
  return (
    <>
      {activeBrands.map((brandName) => {
        const brand = brandByName?.[brandName];
        const brandStats = stats.accumulated[brandName];
        if (!brandStats || !brand) return null;
        return (
          <BrandRow
            key={brand.name}
            brand={brand}
            stats={brandStats}
            info={stats.info}
          />
        );
      })}
    </>
  );
}

interface BrandRowProps {
  brand: Brand;
  stats: BrandStatsBySize;
  info: SocketVideoInfo;
}

function BrandRow({ brand, stats, info }: BrandRowProps) {
  return (
    <Row>
      <Logo src={brand.img} alt={brand.label} />
      <BrandAttributeChart
        stats={stats}
        info={info}
        label={"Visibility"}
        type={LiveStatsValue.visibility_relative}
      />
      <BrandAttributeChart
        stats={stats}
        info={info}
        label={"Duration"}
        type={LiveStatsValue.lduration}
      />
      <BrandAttributeChart
        stats={stats}
        info={info}
        label={"Ø Exclusivity"}
        type={LiveStatsValue.exclusive}
      />
      {/*<BrandAttributeChart*/}
      {/*  stats={stats}*/}
      {/*  info={info}*/}
      {/*  label={"Ø Scale"}*/}
      {/*  type={LiveStatsValue.scaleSum}*/}
      {/*/>*/}
    </Row>
  );
}

const Logo = styled.img`
  min-width: 2em;
  max-width: 4em;
  align-self: center;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
  & > * {
    flex: 1;
  }
`;
