import { StatsByBrand } from "@core/types/domain/livestream/BrandStats";
import { useMemo } from "react";
import { BrandStats } from "@core/types/detection/DetectionTypes";
import { useDebounce } from "use-debounce";
import { getTopStats } from "~/screens/external/live/utils/useTopBrands";
import { useBrands } from "~/services/brands/fetchBrands";
import { PieChartData } from "~/components/charts/BetterPieChart";
import { notNull, sum } from "~/utils/jsUtils";
import { ALL_KEY } from "~/utils/const";

export function usePieChartDataFromStats(stats_: StatsByBrand, limit = 20) {
  const { brandByName } = useBrands();
  const [stats] = useDebounce(stats_, 1500, { leading: true });
  return useMemo<PieChartData[]>(() => {
    if (!stats) return null;
    const tops = getTopStats(stats, getValue);
    const data = tops
      .map<PieChartData>((s) => {
        if (!s[ALL_KEY]) return;
        const brandName = s[ALL_KEY].name;
        const brand = brandByName?.[brandName];
        const value = getValue(s[ALL_KEY]);
        return {
          label: brand?.label || brandName,
          color: brand?.color,
          value,
        };
      })
      .filter(notNull);
    const { items, rest } = limitResults(data, limit);
    if (rest.length) {
      items.push({
        label: "Rest",
        value: sum(rest, (r) => r.value),
        color: "#efefef",
      });
    }
    return items;
  }, [stats, brandByName]);
}

function limitResults<T>(items: T[], limit: number) {
  if (items.length <= limit) {
    return {
      items,
      rest: [],
    };
  } else {
    return {
      items: items.slice(0, limit),
      rest: items.slice(-items.length + limit),
    };
  }
}

const getValue = (s: BrandStats) => s?.visibilityRelative;
